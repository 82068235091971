import { Component, Inject } from "@angular/core";
import { ResponsiveService } from "src/app/services/responsive.service";
import { DragAndDropGridComponent } from "../drag-and-drop-grid.component";
import { WorkloadDay } from "src/app/custom-classes/Workload/WorkloadDay";
import { M_Action } from "src/app/models/M_Action";
import { M_Center } from "src/app/models/M_Center";
import { M_User } from "src/app/models/M_User";
import { M_Schedule } from "src/app/models/M_Schdeule";

/** -------------- */
@Component({
  selector: 'app-week-grid',
  templateUrl: './week-grid.component.html',
  styleUrls: ['../drag-and-drop-grid.component.css']
})
export class WeekGridComponent {
  constructor(@Inject(DragAndDropGridComponent) public grid: DragAndDropGridComponent, public respoisiveS: ResponsiveService) { }

  dayPercentage(day: WorkloadDay) {
    let uF = this.grid.parent.userFilter.userFilter;
    let users = uF.noOneFilter ? [] : !uF.users.length ? undefined : uF.users;
    if (this.grid.parent.center) {
      return this.calculateOccupation(day.v, day.data as M_Action[], this.grid.parent.center, users)
    }
    return 0;
  }

  calculateOccupation(refDay: Date, ors: M_Action[], center: M_Center, users: M_User[] | undefined): number {

    // Check center holidays
    if (center.holidays.some(d => d.isEquals(refDay))) { return 0; }
    /** Check no one filter */
    if (users != undefined && users.length == 0) { return 0; }

    // Removing not estimataed time OR
    let finalor = ors.filter(or => (or.estimated_time_hours || or.estimated_time_minutes));
    //Removing the users that has holidays on 'refDay'
    finalor = finalor.filter(or => or.assigned && or.assigned.holidays.every(h => { return !h.isEquals(refDay) }));
    //Removing user according to the user filter
    if (users != undefined) {
      finalor = finalor.filter(or => or.assigned && users.some(u => u.id == or.assigned?.id));
    }
    //Early return condition
    if (finalor.length == 0) { return 0; }

    /** Or and user total hours */
    let orH: number = 0;
    let userH: number = 0;
    finalor.forEach(or => {
      orH += or.estimated_time_hours || 0;
      orH += or.estimated_time_minutes ? or.estimated_time_minutes / 60 : 0;
      userH += or.getAssignedAvaliableHours(center.exceptions.find(exception => exception.day_exceptional?.isEquals(refDay)));
    })

    let occcupationPercentage = (orH / userH) * 100;
    return occcupationPercentage.castDecimals(2);;
  }

}