/**
 * ENTORNO DE DESARROLLO
 * 
 * Para arrancar en local :
 * ng serve --configuration=pre
 * ng serve --open ('por defecto')
 * 
 * Para generar versión en pre-producción
 * ng build --configuration=pre
 * ng build ('por defecto')
 */

export const environment = {
    local : false,
    production: false,
    preproduction : false,
    dev : true,
    //apiUrl : "http://siniwin.drimet.net"
    apiUrl : "https://weberp.sinigual.com",
    analyticsId : ""
};
  